theme.CollectionFilters = (function() {
  var selectors = {
    filterGroup: '.collection-sidebar__group',
    tagList: '.tag-list',
    tag: '.tag',
    showMoreTrigger: '[data-sten-show-more]'
  };

  function initFilters() {
    var filterGroups = document.querySelectorAll(selectors.filterGroup);
    
    filterGroups.forEach(function(group) {
      var tagLists = group.querySelectorAll(selectors.tagList);
      
      tagLists.forEach(function(tagList) {
        var tags = tagList.querySelectorAll(selectors.tag);
        if (tags.length > 7) {
          setupShowMore(tagList, tags);
        }
      });
    });
  }

  function setupShowMore(tagList, tags) {
    // Remove any existing trigger first
    var existingTrigger = tagList.parentNode.querySelector('[data-sten-show-more]');
    if (existingTrigger) {
      existingTrigger.remove();
    }

    var showMoreTrigger = document.createElement('button');
    showMoreTrigger.className = 'sten-show-more';
    showMoreTrigger.setAttribute('data-sten-show-more', '');
    showMoreTrigger.innerHTML = '<span class="sten-show-more__text">Show more</span>';
    
    // Initially hide extra tags
    tags.forEach(function(tag, index) {
      if (index >= 7) {
        tag.style.display = 'none';
        tag.classList.add('sten-hidden');
      }
    });
    
    showMoreTrigger.addEventListener('click', function(evt) {
      evt.preventDefault();
      var isExpanded = this.getAttribute('data-expanded') === 'true';
      
      tags.forEach(function(tag, index) {
        if (index >= 7) {
          if (isExpanded) {
            tag.style.display = 'none';
            tag.classList.add('sten-hidden');
          } else {
            tag.style.display = '';
            tag.classList.remove('sten-hidden');
          }
        }
      });
      
      var textSpan = this.querySelector('.sten-show-more__text');
      textSpan.textContent = isExpanded ? 'Show more' : 'Show less';
      this.setAttribute('data-expanded', String(!isExpanded));
    });
    
    tagList.parentNode.insertBefore(showMoreTrigger, tagList.nextSibling);
  }

  return {
    init: function() {
      initFilters();

      // Reinitialize filters when collection is reloaded
      document.addEventListener('collection:reloaded', function() {
        setTimeout(initFilters, 0);
      });
    }
  };
})();

// Initialize on both DOMContentLoaded and page load
document.addEventListener('DOMContentLoaded', function() {
  theme.CollectionFilters.init();
});

window.addEventListener('load', function() {
  theme.CollectionFilters.init();
});